import _ from 'lodash'
import EmployeeRole from './Enum'

class Global {
  /* eslint no-underscore-dangle: ["error", { "allow": ["_currentUser", "_currentUserRole", "_"] }] */
  constructor() {
    this._currentUser = {}
    // this._currentUserRole = 'ROLE_USER'
  }

  getCurrentUser() {
    return this._currentUser
  }

  setCurrentUser(user) {
    this._currentUser = user
    console.log({ user })
    this.setCurrentUserRole(user?.roles)


    // Object.keys(EmployeeRole).forEach(key => {
    //   if (user.roles.filter(item => item === key)) {
    //     this.setCurrentUserRole(key)
    //   }
    // })
  }

  isUserAuthenticated() {
    if (_.isEmpty(this._currentUser)) {
      return false
    }
    return true
  }

  getCurrentUserRole() {
    return this._currentUserRole
  }

  setCurrentUserRole(role) {
    // default - ROLE_USER
    this._currentUserRole = role
  }

  isAdmin() {
    if (this._currentUser.roles) {
      const check = this._currentUser.roles.filter(item => item.name === EmployeeRole.ADMIN)
      if (check.length > 0) {
        return true
      }
      return false
    }
    return false
  }

  isMoreThanUser() {
    if (this._currentUser.roles) {
      const check = this._currentUser.roles.filter(item => item.name !== EmployeeRole.USER)
      if (check.length > 0) {
        return true
      }
      return false
    }
    return false
  }

  isModerator() {
    const check = this._currentUser.roles.filter(item => item.name === EmployeeRole.MODERATOR)
    if (check.length > 0) {
      return true
    }
    return false
  }

  isOnlyUser() {
    const check = this._currentUser.roles.filter(item => item.name === EmployeeRole.USER)
    if (check.length > 0) {
      return true
    }
    return false
  }
}

export default new Global()
