import axios from 'axios'
import { BASE_URL, unAuthUrls } from '../hosts'
import refreshAccessToken from './refresh'

// Функция для обновления токена

// Основная функция GET-запроса
const getMethod = async url => {
  const token = localStorage.getItem('token') || localStorage.getItem('user_token')?.slice(1, -1)
  let headers = {}

  if (unAuthUrls.indexOf(url) === -1 && !unAuthUrls.find(x => url.indexOf(x) > -1)) {
    headers = { Authorization: `Bearer ${token}` }
  }

  const fullUrl = `${BASE_URL}${url}`

  try {
    return await axios({ method: 'GET', url: fullUrl, headers })
  } catch (err) {
    if (err.response && err.response.status === 401) {
      // Попытка обновить токен
      const newToken = await refreshAccessToken()
      if (newToken) {
        headers.Authorization = `Bearer ${newToken}`
        return axios({ method: 'GET', url: fullUrl, headers })
      }
    } else {
      // Обработка других ошибок
      console.error('Error in getMethod: ', err.message)
    }

    // Возвращаем null или выбрасываем исключение, если запрос не удался
    return null
  }
}

export default getMethod
