import axios from 'axios'
import { BASE_URL, unAuthUrls } from '../hosts'
import refreshAccessToken from './refresh'

// Основная функция POST-запроса
const postMethod = async (url, data, basePath = null) => {
  console.log({ url, data })
  const token = localStorage.getItem('token') || localStorage.getItem('user_token')?.slice(1, -1)
  const headers = unAuthUrls.indexOf(url) === -1 && {
    Authorization: `Bearer ${token}`,
    'content-type': url.indexOf('asset') > -1 ? 'multipart/form-data' : 'application/json',
  }

  const fullUrl = `${basePath || BASE_URL}${url}`

  try {
    return await axios({ method: 'POST', url: fullUrl, data, headers })
  } catch (err) {
    if (err.response && err.response.status === 401) {
      // Попытка обновить токен
      const newToken = await refreshAccessToken()
      if (newToken) {
        headers.Authorization = `Bearer ${newToken}`
        return axios({ method: 'POST', url: fullUrl, data, headers })
      }
    } else {
      // Обработка других ошибок
      console.error('Error in postMethod: ', err.message)
    }

    // Возвращаем false или другое значение, если запрос не удался
    return false
  }
}

export default postMethod
