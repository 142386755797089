import axios from 'axios'
import { BASE_URL } from '../hosts'

export default async function refreshAccessToken() {
  try {
    const refreshToken = localStorage.getItem('refresh_token')
    const response = await axios.post(`${BASE_URL}/auth/account/refresh`, { refreshToken })
    const { access_token: accessToken, refresh_token: newRefreshToken } = response.data
    localStorage.setItem('token', accessToken)
    if (newRefreshToken) {
      localStorage.setItem('refresh_token', newRefreshToken)
    }
    return accessToken
  } catch (error) {
    console.error('Error refreshing access token: ', error.message)
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
    window.location = '/login'
    return null
  }
}
