const EmployeeRole = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  MODERATOR: 'MODERATOR',
  OPERATOR: 'OPERATOR',
  INVESTOR: 'INVESTOR',
  USER: 'USER',
  STOCKMAN: 'STOCKMAN',
}

export default EmployeeRole
