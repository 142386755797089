import { notification } from 'antd'
import Global from '../components/Global'
import postMethod from '../API/rest/post'
import getMethod from '../API/rest/get'

export async function login(userName, password) {
  return postMethod('/auth/account/login', { userName, password })
    .then(res => {
      console.log(res)
      if (res?.data?.access_token && !res?.data?.roles?.find(x => x === 'USER')) {
        localStorage.setItem('token', res?.data?.access_token)
        localStorage.setItem('refresh_token', res?.data?.refresh_token)
        console.log(res?.data)
        // Global.setCurrentUser(res?.data)
        return true
      }
      return false
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return false
    })
}

export async function currentAccount() {
  function getCurrentUser() {
    return new Promise(resolve => {
      const token = localStorage.getItem('token')
      if (token) {
        getMethod('/auth/account/profile', token)
          .then(res => {
            console.log(res.data)
            if (res.status === 200) {
              Global.setCurrentUser(res?.data)
              resolve(res.data)
            }
          })
          .catch(err => {
            console.error('Unauthorized:', err.message)
            resolve(err.message)
          })
      } else {
        resolve(false)
      }
    })
  }

  return getCurrentUser()
}

export async function logout() {
  // return firebaseAuth()
  //   .signOut()
  //   .then(() => true)
}
