import React, { useEffect, useState } from 'react'
import { Placemark, YMaps, Map } from 'react-yandex-maps'
import styled from 'styled-components'
import useQuery from '../../services/hooks'

export default function MapComponent() {
  const query = useQuery()

  const [location, setLocation] = useState({ lon: null, lat: null })

  useEffect(() => {
    const lon = parseFloat(query.get('lon') || null)
    const lat = parseFloat(query.get('lat') || null)
    setLocation({ lon, lat })
  }, [])
  return (
    <MapWrapper>
      <MapPlacemark>
        <img src="/resources/images/placemark.png" alt="" />
      </MapPlacemark>
      <YMaps>
        <Map
          defaultState={{ center: [location.lat, location.lon], zoom: 13 }}
          width="100%"
          height="480px"

        >
          <Placemark
            defaultGeometry={[location.lat, location.lon]}
            geometry={[location.lat, location.lon]}
            properties={{
              hintContent: 'Собственный значок метки',
              balloonContent: 'Это красивая метка',
            }}
            options={{
              iconLayout: 'default#image',
              iconImageHref: '/resources/images/placemark.png',
              iconImageSize: [38, 40],
              iconImageOffset: [-3, -42],
            }}
          />
        </Map>
      </YMaps>
    </MapWrapper>
  )
}

const MapWrapper = styled.div`
  position: relative;

`
const MapPlacemark = styled.div`
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
  background-color: transparent;

  img {
    width: 40px;
    height: 40px;
  }
`
